<template>
  <div class="p-1">
    <!-- 查询选项 -->
    <div class="row mb-2">
      <div class="col-md-12">
        <el-card class="card">
          <h3 class="card-title">扣分记录查询</h3>
          <el-form :inline="true" ref="searchForm" :model="searchForm" class="search-form">
            <el-form-item label="扣分类型">
              <template v-if="isWideScreen">
                <el-cascader
                    ref="search_cascader"
                    class="f-width"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    placeholder="选择类型"
                    @change="handleTypeChange"
                    clearable></el-cascader>

              </template>
              <template v-else>
                <el-cascader-panel
                    ref="search_cascader"
                    class="f-width"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    placeholder="选择类型"
                    @change="handleTypeChange"
                    clearable></el-cascader-panel>
                <el-button @click="handleReset" class="f-width">清除</el-button>
              </template>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker v-model="searchForm.startDate" type="date" class="f-width" value-format="yyyy-MM-dd"
                              placeholder="选择起始日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker v-model="searchForm.endDate" type="date" class="f-width" value-format="yyyy-MM-dd"
                              placeholder="选择截止日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>

            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

    <!-- 查询结果 -->
    <div class="row mt-2">
      <div class="col-md-12">
        <el-card>
          <el-table :data="tableData" style="width: 100%" @sort-change="handleSortChange">
            <el-table-column prop="score" label="分数" sortable="custom"></el-table-column>
            <el-table-column prop="category" label="类型" sortable="custom"></el-table-column>
            <el-table-column prop="sub_category" label="子类型" sortable="custom"></el-table-column>
            <el-table-column prop="record_date" label="记录时间" sortable="custom"></el-table-column>
            <el-table-column prop="remarks" label="备注" sortable="custom"></el-table-column>
          </el-table>
          <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              @current-change="handlePageChange"
          />
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";  // 假设你有这个实例配置
import api from "../../api/index";
import {fetchCategories} from "../../api/api_util";

export default {
  data() {

    return {
      isWideScreen: window.innerWidth >= 768, // 可以根据需要调整宽度阈值
      searchForm: {
        category: '',
        sub_category: '',
        startDate: '',
        endDate: ''
      },
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      sortField: '',
      sortOrder: '',
      categories_cascader: []
    };

  },
  methods: {
    handleTypeChange(val) {
      console.log(val);
      const checkedNodes = this.$refs['search_cascader'].getCheckedNodes();
      if (checkedNodes.length === 0) {
        this.searchForm.category = '';
        this.searchForm.sub_category = '';
        return;
      }
      if (checkedNodes[0].pathLabels.length > 0) {
        this.searchForm.category = checkedNodes[0].pathLabels[0];
        if (checkedNodes[0].pathLabels.length > 1) {
          this.searchForm.sub_category = checkedNodes[0].pathLabels[1];
        }
      }
      console.log(this.searchForm);
    },
    handleReset() {
      this.$refs.search_cascader.checkedValue = "";
    },
    handleSearch() {
      // 将查询表单数据转换为请求参数
      const params = {
        category: this.searchForm.category,
        sub_category: this.searchForm.sub_category,
        start_date: this.searchForm.startDate,
        end_date: this.searchForm.endDate,
        page: this.currentPage,
        page_size: this.pageSize,
        sort_field: this.sortField,
        sort_order: this.sortOrder
      };

      // 发送 GET 请求到后端 API
      axiosInstance.get(api.QueryStudentScores, {params})
          .then(response => {
            if (response.data.status === 'success') {
              // 更新表格数据和总数
              this.tableData = response.data.data.scores;
              this.total = response.data.data.total;
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch(error => {
            this.$message.error('获取数据失败：' + error);
          });
    },
    handlePageChange(page) {
      // 更新当前页数并重新发起查询
      this.currentPage = page;
      this.handleSearch();
    },
    handleSortChange({prop, order}) {
      // 更新排序字段和顺序并重新发起查询
      this.sortField = prop;
      this.sortOrder = order === 'ascending' ? 'asc' : 'desc';
      this.handleSearch();
    }
  },
  async mounted() {
    this.categories_cascader = await fetchCategories(false);
    this.handleSearch();
  }
};
</script>

<style scoped>

.el-form-item {
  margin-right: 20px;
}

.f-width {
  width: 220px;
}

.h-width {
  width: 105px !important;
}

.el-cascader-panel {
  max-width: 220px;
  overflow-x: auto; /* 当内容超出宽度时显示横向滚动条 */
}
</style>