<template>
  <div class="p-1">
    <div class="password-change-container">
      <el-card class="card">
        <h3 class="card-title">修改密码</h3>
        <el-form :model="form" :inline="true" :rules="rules" ref="form"  class="password-change-form">
          <el-form-item label="旧的密码" prop="oldPassword">
            <el-input v-model="form.oldPassword"  class="f-width" type="password" autocomplete="off" placeholder="输入旧密码"></el-input>
          </el-form-item>
          <el-form-item label="新的密码" prop="newPassword">
            <el-input v-model="form.newPassword"  class="f-width" type="password" autocomplete="off" placeholder="输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="form.confirmPassword"  class="f-width" type="password" autocomplete="off" placeholder="重复新密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>

import axiosInstance from "../../api/axiosInstance";
import api from "../../api/index";

export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入新密码'));
      } else if (value.length < 6) {
        return callback(new Error('密码长度不能小于6位'));
      } else if (!/[A-Za-z]/.test(value) || !/[0-9]/.test(value)) {
        return callback(new Error('密码必须包含字母和数字'));
      } else if (/['"\\;]/.test(value)) {
        return callback(new Error('密码不能包含引号、反斜杠或分号'));
      } else {
        callback();
      }
    };

    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        return callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };

    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [
          {required: true, message: '请输入旧密码', trigger: 'blur'}
        ],
        newPassword: [
          {required: true, validator: validatePassword, trigger: 'blur'}
        ],
        confirmPassword: [
          {required: true, validator: validateConfirmPassword, trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 提交密码修改请求
          const params = {
            old_password: this.form.oldPassword,
            new_password: this.form.newPassword
          };
          // 使用axiosInstance发送POST请求
          axiosInstance.post(api.ResetPassword, params)
              .then(response => {
                if (response.data.status === 'success') {
                  this.$message.success('密码修改成功');
                  this.handleReset();
                } else {
                  this.$message.error(response.data.message);
                }
              })
              .catch(error => {
                this.$message.error('密码修改失败');
              });
        } else {
          this.$message.error('请完善表单信息');
          return false;
        }
      });
    },
    handleReset() {
      this.$refs.form.resetFields();
    }
  }
};
</script>

<style scoped>

.card {
  max-width: 400px;
  margin: 0 auto;
}

.password-change-form {
  width: 100%;
}

.f-width {
  width: 210px;
}

.h-width {
  width: 105px !important;
}
</style>